import React, { useContext } from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import { Link, Text } from "rebass"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons"

import styles from "./Contacts.module.css"
import "mapbox-gl/dist/mapbox-gl.css"
import { responsive } from "../style-utils"
import CopyButton from "../components/CopyButton"
import DeviceContext from "../context/DeviceContext"
import SocialIcon from "../components/SocialIcon"

import Twitter from "../images/social/twitter.png"
import Telegram from "../images/social/telegram.png"
import Facebook from "../images/social/facebook.png"

import SocialLinks from "../constants/SocialNetworks"
import Map from "../components/map"

const today = new Date()

const Section = styled.section`
  height: 100vh;
`
const ParentContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  align-content: center;
  height: 100%;
  width: 100%;
  background: linear-gradient(4deg, #fff 82%, #f8f8f8 82%);
`

const PaperPlane = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 14rem;
  top: 6rem;
  display:none;

  ${responsive.mobile`
    opacity:.6;
    display: none;
  `}
`

const RepresentativeContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  flex-direction: column;
  align-items: center;
  height: 61%;
  width: 100%;
  z-index: 200;
 
  ${responsive.mobile`
    justify-content: flex-end;
    width: 100%;
  `}
`

const InfoContainer = styled.div`
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  bottom: 0;
  left: 0;
  flex-direction: column;
  height: 12rem;
  justify-content: center;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 20;


  ${responsive.mobile`
    height: 15rem;
    max-height: 39%;
    justify-content: flex-start;
    padding-top: 1rem;
  `}
`

const MobileInfoContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 20;
  padding: 20px;
`

const TextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  z-index: 100;
  padding-left: 10rem;
  padding-top: 0;
  padding-bottom: 0;
  position: absolute;
  bottom: 1rem;
  font-weight: 200;

  ${responsive.mobile`
    padding: 0 2rem 1rem 2rem;
    flex-direction: column-reverse;
    > div {
      width: 100%;
      &:first-child {
        padding-top:.8rem;
      }
    }
  `}
`

const TextHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  z-index: 100;
  padding: 0 10rem;
  font-weight: 200;

  ${responsive.mobile`
    padding: 0 2rem;
  `}
`

const TextContainerColumn = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  width: ${props => props.width || "100%"};
  z-index: 100;
  font-weight: 200;
`

const MobileLocationContainer = styled.div`
  position: relative;
  min-height: 380px;
  width: 100%;
`


const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const SocialContainer = styled.div`
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: 40px;
   margin-bottom: 40px;
`
const HorizontalLine = styled.div`
  height: 1px;
  width: 90%;
  background-color: #979A9D;
  border-radius: 20px;
  opacity: 0.3;
  margin: 0 auto 15px;
`
const HeroText = () => (
  <>
    <TextHeader>
      <TextContainerColumn>
        <Text
          fontSize={[3, 4]}
          color="lightWhite"
          fontFamily="IBM Plex Sans"
          textAlign="left"
          pb={1}
        >
          For new projects
        </Text>
        <Text
          color="lightWhite"
          fontSize={[1, 2]}
          fontFamily="IBM Plex Sans"
          textAlign="left"
          pb={1}
        >
          <Link
            href="mailto: hello@geekchester.com"
            fontSize={[3, 4]}
            color="lightWhite"
            css={{
              textDecoration: "underline",
            }}
          >
            hello@geekchester.com
          </Link>

        </Text>
      </TextContainerColumn>
      <PaperPlane>
        <FontAwesomeIcon icon={faPaperPlane} color="#F6682F" size="3x"/>
      </PaperPlane>

    </TextHeader>
    <TextContainer>
      <TextContainerColumn width="50%">
        <Text
          color="lightWhite"
          fontSize=".7rem"
          fontFamily="IBM Plex Sans"
          textAlign="left"
          css={{
            textTransform: "uppercase",
          }}
        >
          All rights reserved {today.getFullYear()} © Geekchester OÜ
        </Text>
      </TextContainerColumn>
      <TextContainerColumn width="25%">
        <Text
          color="lightWhite"
          fontSize=".7rem"
          fontFamily="IBM Plex Sans"
          textAlign="left"
          css={{
            textTransform: "uppercase",
          }}
        >
          E: hello@geekchester.com <br/>
          T: +372 58 064 703
        </Text>
      </TextContainerColumn>
      <TextContainerColumn width="25%">
        <Text
          color="lightWhite"
          fontSize=".7rem"
          fontFamily="IBM Plex Sans"
          textAlign="left"
          css={{
            textTransform: "uppercase",
          }}
        >
          Valukoja 8/2, 11415<br/>
          Tallinn, Estonia
        </Text>
      </TextContainerColumn>
    </TextContainer>
  </>
)

const DesktopLocation = () => {
  return (
    <>
      <div className={styles.staticMap}>
        <Map />
      </div>

      <InfoContainer className="info-container">
        <HeroText/>
      </InfoContainer>
    </>
  )
}

const MobileLocation = () => (
  <MobileLocationContainer className={styles.staticMap}>
    <MobileInfoContainer>
      <Text
        color="lightWhite"
        fontSize="1.4rem"
        fontFamily="IBM Plex Sans"
        textAlign="left"
        zIndex="1000"
      >
        Contact us:
      </Text>

      <FlexRow>
        <a href="mailto:hello@geekchester.com">
          <Text
            color="#F6682F"
            fontSize="1.3rem"
            fontFamily="IBM Plex Sans"
            fontWeight="300"
            textAlign="left"
            css={{
              textDecoration: "underline",
            }}
          >
            hello@geekchester.com
          </Text>
        </a>

        <CopyButton copyText="hello@geekchester.com"/>
      </FlexRow>

      <FlexRow>
        <a href="tel:+37258064703">
          <Text
            color="#F6682F"
            fontSize="1.3rem"
            fontFamily="IBM Plex Sans"
            textAlign="left"
            fontWeight="300"
            css={{
              textDecoration: "underline",
            }}
          >
            +372 58064703
          </Text>
        </a>

        <CopyButton copyText="+372 58064703"/>
      </FlexRow>

      <SocialContainer>
        <SocialIcon src={Twitter} link={SocialLinks.twitterLink}/>
        <SocialIcon src={Telegram} link={SocialLinks.telegramLink}/>
        <SocialIcon src={Facebook} link={SocialLinks.facebookLink}/>
      </SocialContainer>

      <Text
        color="lightWhite"
        fontSize=".8rem"
        fontFamily="IBM Plex Sans"
        textAlign="left"
        css={{
          textTransform: "uppercase",
          marginBottom: "20px",
        }}
      >
        E: MAIL@GEEKCHESTER.COM
        <br/>
        <br/>
        Valukoja 8/2, 11415<br/>
        Tallinn, Estonia
      </Text>

      <HorizontalLine/>

      <Text
        color="lightWhite"
        fontSize=".7rem"
        fontFamily="IBM Plex Sans"
        textAlign="center"
        fontWeight="300"
        css={{
          textTransform: "uppercase",
        }}
      >
        All rights reserved {today.getFullYear()} © Geekchester OÜ
      </Text>
    </MobileInfoContainer>

    <Map />
  </MobileLocationContainer>
)

const Location = () => {
  const device = useContext(DeviceContext)

  if (device.isMobile) {
    return <MobileLocation/>
  }

  return <DesktopLocation/>
}

const Contacts = ({ index }) => (
  <StaticQuery
    query={graphql`
      query ContactsNewQuery {
        allContentfulContact(
          filter: { node_locale: { eq: "en-US" } }
          sort: { fields: [updatedAt], order: DESC }
        ) {
          edges {
            node {
              landingBackground {
                heroBackground {
                  file {
                    url
                  }
                }
              }
              legalInfo
              addressLine1
              addressLine2
              contactChannels {
                icon
                childContentfulContactChannelContentTextNode {
                  content
                }
              }
            }
          }
        }
        allContentfulGeek(
          filter: { node_locale: { eq: "en-US" } }
          sort: { fields: [updatedAt], order: DESC }
        ) {
          edges {
            node {
              name
              position
              profilePicture {
                file {
                  url
                }
              }
              contactChannels {
                icon
                childContentfulContactChannelContentTextNode {
                  content
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Section className="section" style={{ zIndex: index }} id="contact-anchor">
        <ParentContainer>
          <RepresentativeContainer>
            <div className={styles.representative}/>
          </RepresentativeContainer>

          <Location/>
        </ParentContainer>
      </Section>
    )}
  />
)

export default Contacts
