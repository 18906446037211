import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import CopyToClipboard from "react-copy-to-clipboard"
import classNames from "classnames"

const COPY_TEXT = "Copy"
const COPIED_TEXT = "Copied"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 24px;
  min-width: 55px;
  
  border-bottom: 2px solid #F6682F;
`
const TextContainer = styled.div`
  color: #FFF;
  font-size: 0.9rem;
  font-family: "IBM Plex Sans", sans-serif;
  text-transform: uppercase;
  overflow: hidden;
  
  display: flex;
`

const CopiedText = styled.div`
  opacity: 0;
  width: 0;
  overflow: hidden;

  &.pulse {
    width: 100%;
    display: block;
    animation: pulse 2s ease-in-out;
  }
  
  
  @keyframes pulse {
    0% {opacity: 0}
    10% {opacity: 0.15}
    20% {opacity: 0.3}
    30% {opacity: 0.45}
    40% {opacity: 0.60}
    50% {opacity: 0.75}
    60% {opacity: 1}
    70% {opacity: 0.75}
    80% {opacity: 0.6}
    90% {opacity: 0.45}
    100% {opacity: 0.15}
  }
`

const CopyText = styled.div`
  width: 100%;
  opacity: 1;
  transition: opacity 1.5s ease-in-out;
  
  &.hidden {
    width: 0px;
    opacity: 0;
    overflow: hidden;
  }
`


const CopyButton = ({ copyText }) => {
  const [pulse, setPulse] = useState(false)
  const [showCopy, setShowCopy] = useState(true)

  const copy = () => {
    setPulse(true);
    setShowCopy(false)
    setTimeout( () => {setPulse(false)}, 2100)
    setTimeout( () => {setShowCopy(true)}, 2300)
  }

  return (
    <Container>
      <CopyToClipboard text={copyText} onCopy={copy}>

          <TextContainer>
            <CopyText className={classNames({hidden: !showCopy})}>{COPY_TEXT}</CopyText>
            <CopiedText className={classNames({pulse})}>{COPIED_TEXT}</CopiedText>
          </TextContainer>

      </CopyToClipboard>
    </Container>
  )
}

CopyButton.propTypes = {
  copyText: PropTypes.string.isRequired,
}

export default CopyButton