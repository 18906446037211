import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  background: #F6682F;
  border-radius: 20px;
  margin-right: 15px;
`

const SocialIcon = ({ src, link }) => (
  <a href={link} target="_blank" rel="noopener noreferrer">
    <ImageContainer>
      <img src={src} alt="" />
    </ImageContainer>
  </a>
)

SocialIcon.propTypes = {
  src: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}

export default SocialIcon