import { Marker, StaticMap } from "react-map-gl"
import React from "react"

const Map = () => {
  const MAP_CONFIG = {
    viewport: {
      width: "100%",
      height: "100%",
      latitude: 59.42038,
      longitude: 24.8053,
      zoom: 11,
    }
  }

  return (
    <StaticMap
      {...MAP_CONFIG.viewport}
      style={{ zIndex: 19 }}
      mapStyle="mapbox://styles/mapbox/dark-v10"
      mapboxApiAccessToken="pk.eyJ1IjoicGxhdnJlc2hpbiIsImEiOiJjanBzMmhtdXcwemQ5NDluMHoyNmF3azAyIn0.dAfruGxB5F3mV9se2lkUpQ"
      attributionControl={false}
      reuseMaps>
      <Marker
        latitude={MAP_CONFIG.viewport.latitude}
        longitude={MAP_CONFIG.viewport.longitude}
      >
        <div
          className="marker"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            cursor: "auto",
            color: "white",
            backgroundColor: "#F6682F",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            borderStyle: "solid",
            borderWidth: "10px",
            borderColor: "#44291e",
            fontFamily: "IBM Plex Sans",
            fontWeight: "300",
          }}
        >
          G
        </div>
      </Marker>
    </StaticMap>
  )
}

export default Map