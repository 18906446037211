import { css } from 'styled-components';

export const MAX_MOBILE_WIDTH = 812; // iphone X

export const breakpoints = {
    xs: { width: `${MAX_MOBILE_WIDTH}px`, use: 'max', },
    mobile: { width: '1124px', use: 'max', },
    desktop: { width: '1125px', use: 'min', },
};

export const responsive = Object.keys(breakpoints).reduce((accumulator, label) => {
	accumulator[label] = (...args) => css`
		@media (${breakpoints[label].use}-width: ${breakpoints[label].width}) {
			${css(...args)};
		}
	`;
	return accumulator;
}, {});

export const iphone5 = (...args) => css`
    @media (max-height: 568px) {
        ${css(...args)};
    }
`;

export const fade = (visible, time) => visible ? `
	transition: all ${time} ease-in-out;
	opacity: 1;
	visibility: visible;
` : `
	opacity: 0;
	visibility: hidden;
`